import CheckCircle from '@/components/common/IconsV2/CheckCircle';
import NextImage from '@/components/common/NextImage';
import React from 'react';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

const BuildWithYou = ({ title, trainerImages, items }) => {
  const { isGtEqMd } = useWindowWidthContext();
  const renderTrainersRow = () => {
    return trainerImages.map((image, index) => {
      // We have odd numbers of trainer images, and it doesn't look good in a grid of even columns. We will only use 4
      if (!isGtEqMd && index >= 4) {
        return null;
      }
      return (
        <div className="flex items-center justify-center" key={index}>
          <NextImage {...image} className={`rounded-full w-full h-full`} />
        </div>
      );
    });
  };

  const renderItemList = () => {
    return (
      <div className={`flex flex-col items-start w-full md:items-center`}>
        {items.map((item, index) => {
          return (
            <div
              className="flex flex-row items-center justify-center mb-24"
              key={index}>
              <CheckCircle
                customClassNames={'h-32 w-32 md:h-40 md:w-40'}
                fill={'#FBC91B'}
              />
              <span className="ml-18 text-heading-xs font-semibold text-[#1b1b1b] md:text-32 md:text-heading-lg">
                {item}
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="mx-auto px-24 py-64 md:px-[40px] md:py-[80px] xl:px-0 xl:max-w-[1120px]">
      <h2
        className="text-heading-xl text-[#1b1b1b] font-semibold mb-32 md:mb-40 md:text-center md:text-display-sm"
        data-testid="homepage-trainers-title">
        {title}
      </h2>
      <div className="flex space-x-16 mb-[56px] md:mb-[64px] md:space-x-24 justify-center">
        {renderTrainersRow()}
      </div>
      {renderItemList()}
    </div>
  );
};

export default BuildWithYou;
