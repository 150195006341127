import classNames from 'classnames';
import React from 'react';
import IconByName from './IconsV2/IconByName';

const TabsV2 = (props) => {
  const {
    tabs,
    activeTabId,
    onTabClick,
    customContainerClassNames,
    customTabClassNames,
    labelClassNames,
    activeLabelClassNames,
    strikeClassNames,
    showHoveStrike = false
  } = props;

  return (
    <div className="c-TabsV2">
      <div
        className={`flex border-b-1 border-neutral-95 ${customContainerClassNames}`}>
        {tabs.map((tab) => {
          const { id, icon, label, testId } = tab;
          const isActive = id === activeTabId;

          return (
            <div
              data-testid={testId}
              key={id}
              className={`group relative h-36 mr-40 ${customTabClassNames} ${
                !isActive ? 'opacity-50' : ''
              }`}
              onClick={() => onTabClick(id)}
              role="button"
              tabIndex={0}>
              <p
                className={`py-4 px-8 text-label-md font-medium text-dark-1b flex items-center ${
                  isActive ? activeLabelClassNames : labelClassNames
                }`}>
                {icon && (
                  <span className="mr-8">
                    <IconByName
                      name={icon}
                      fill="#1B1B18"
                      width="16"
                      height="16"
                    />
                  </span>
                )}
                {label}
              </p>

              {showHoveStrike && !isActive && (
                <div
                  className={classNames(
                    `absolute bottom-0 bg-npl-neutral-light-7 h-4 w-full rounded-t-8 hidden group-hover:block`,
                    strikeClassNames
                  )}></div>
              )}
              {isActive && (
                <div
                  className={`absolute bottom-0 bg-primary h-4 w-full rounded-t-8 ${strikeClassNames}`}></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TabsV2.defaultProps = {
  customTabClassNames: '',
  customContainerClassNames: ''
};

export default TabsV2;
