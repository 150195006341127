import RoundedButton from '@/components/common/RoundedButton';
import React from 'react';

const BrandsHomeSection = ({
  title,
  description,
  CTABtnText,
  firstBrandRow,
  secondBrandRow
}) => {
  return (
    <div className=" font-poppins bg-npl-yellow-light-6 lg:px-[120px] md:px-[40px] sm:px-[24px] px-24 py-[80px]">
      <div className="flex flex-col items-center max-w-[960px] mx-auto ">
        <h1 className="font-semibold md:text-center text-left text-npl-text-icon-on-light-surface-primary md:text-display-sm max-w-[600px] text-heading-xl">
          {title}
        </h1>
        <p className="mt-24 font-normal md:text-center text-left text-body-md max-w-[600px] text-npl-text-icon-on-light-surface-secondary/65">
          {description}
        </p>

        <div className="w-full mt-24 md:w-fit">
          <RoundedButton
            displayType="neutral"
            onClick={() => {}}
            customClassNames="bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary !h-[56px] !font-medium w-full flex justify-center md:w-fit md:mx-auto">
            <div className="flex items-center justify-center space-x-8">
              <a href="/application/contact-us">{CTABtnText}</a>
            </div>
          </RoundedButton>

          <div className="flex flex-col mt-40 md:flex-row ">
            <div className="flex flex-row space-x-[30px] md:justify-start justify-around mr-[30px] w-full items-center">
              {firstBrandRow.map((brand, index) => {
                return (
                  <img
                    key={index}
                    alt="brand"
                    src={brand}
                    className="object-contain max-w-[96px] max-h-[48px] h-full w-full"
                  />
                );
              })}
            </div>
            <div className="flex flex-row space-x-[30px] md:mt-0 mt-24 md:ml-[30px] md:justify-start justify-around ml-0 w-full items-center">
              {secondBrandRow.map((brand, index) => {
                return (
                  <img
                    key={index}
                    alt="brand"
                    src={brand}
                    className="object-contain"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandsHomeSection;
