import FaqAccordian from '@/components/common/FaqAccordian/FaqAccordian';

const FAQSection = ({ title, ...props }) => {
  return (
    <div className="px-24 py-64 md:p-100">
      <h2
        className="text-heading-xl text-[#1b1b1b] font-semibold mb-48 md:text-display-sm md:text-center"
        data-testid="homepage-testimonial-v2-title">
        {title}
      </h2>
      <FaqAccordian {...props} hideTitle customClassNames="!p-0" />
    </div>
  );
};

export default FAQSection;
