export const BANNER = 'banner';
export const TEXT_BANNER = 'text_banner';
export const TEXT_BANNER_V2 = 'text_banner_v2';
export const MONETIZATION_SECTION = 'monetizationSection';
export const COMMUNITY_TOOLS = 'community_tools';
export const POS_CONS_SECTION = 'pros_cons_section';
export const PRODUCT_WALKTHROUGH = 'product_walkthrough';
export const TESTIMONIAL_V2 = 'testimonial_v2';
export const VIDEO_MODAL_SECTION = 'video_modal_section';
export const COMMUNITIES_CAROUSEL = 'communitiesCarousel';
export const HOW_NASIO_WORKS = 'howNasWorks';
export const TESTIMONIAL = 'testimonial';
export const COMMUNITY_OVERVIEW = 'communityOverview';
export const NASIO_IS_FOR = 'nasioIsFor';
export const TRAINERS = 'trainers';
export const START_COMMUNITY = 'startCommunity';
export const META_DATA = 'metaData';
export const INFO_WITH_GRAPHIC = 'infoWithGraphic';
export const INFO_WITH_GRAPHIC_LIST = 'infoWithGraphicList';
export const SINGLE_TESTIMONIAL_SECTION = 'singleTestimonialSection';
export const BRANDS_SECTION = 'brandsSection';
export const MONEY_SLIDER = 'moneySlider';
export const COMMUNITY_CATEGORIES = 'communityCategories';
export const FAQ_SECTION = 'faqSection';

export const INDIA_TIMEZONES = ['Asia/Kolkata', 'Asia/Calcutta'];

export const MONTHLY_MEMBERSHIP = 'monthly-membership';
export const SELL_CONTENT = 'sell-content';
export const PAID_EVENTS = 'paid-events';
export const BRAND_SPONSORSHIP = 'brand-sponsorship';
export const FOLLOWER_COUNT = [
  0, 10000, 20000, 50000, 100000, 250000, 500000, 1000000, 5000000
];
export const MONTHLY_RECURRING_REVENUE = [
  0, 1000, 2000, 5000, 10000, 12000, 25000, 75000, 100000
];
export const MONTHLY_EVENTS_SALES_REVENUE = [
  0, 300, 600, 1500, 3000, 3600, 7500, 22500, 30000
];
export const MONTHLY_CONTENT_SALES_REVENUE = [
  0, 400, 800, 2000, 4000, 4800, 10000, 30000, 40000
];
export const MONTHLY_BRAND_SPONSORSHIPS = [
  0, 300, 600, 1500, 3000, 3600, 7500, 22500, 30000
];
export const CANON_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/Canon.png';
export const GRAB_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/Grab.png';
export const METAPURSE_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/MetaPurse.png';
export const PFIZER_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/Pfizer.png';
export const SINGAPORE_AIRLINES_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/SingaporeAirlines.png';
export const TIKTOK_IMAGE =
  'https://d2oi1rqwb0pj00.cloudfront.net/nasIO/new_home/png/TikTok.png';
