import React from 'react';

const WhatsappBannerIcon = ({ width, height }) => {
  return (
    <svg width="129" height={height} viewBox="0 0 129 160" fill="none">
      <g>
        <path
          d="M103.545 89.425C101.72 88.775 100.395 88.435 99.065 90.44C97.765 92.395 93.935 96.87 92.765 98.175C91.595 99.48 90.445 99.58 88.47 98.695C86.465 97.68 80.06 95.595 72.455 88.775C66.515 83.49 62.56 76.98 61.385 74.975C60.215 72.995 61.255 71.875 62.245 70.885C63.155 69.975 64.25 68.595 65.265 67.395C66.23 66.195 66.54 65.39 67.245 64.09C67.895 62.685 67.56 61.59 67.065 60.6C66.57 59.61 62.585 49.795 60.92 45.885C59.33 42.005 57.665 42.5 56.44 42.5C55.295 42.395 53.965 42.395 52.64 42.395C51.315 42.395 49.15 42.89 47.325 44.79C45.5 46.795 40.345 51.585 40.345 61.275C40.345 70.99 47.48 80.39 48.47 81.795C49.485 83.095 62.505 103.095 82.48 111.69C87.245 113.695 90.945 114.895 93.835 115.885C98.6 117.395 102.95 117.185 106.385 116.69C110.185 116.065 118.155 111.87 119.825 107.185C121.52 102.47 121.52 98.565 121.025 97.68C120.53 96.77 119.23 96.275 117.225 95.39C115.442 94.6031 105.401 90.1164 103.545 89.425ZM80.915 145H80.81C68.985 145 57.295 141.795 47.085 135.805L44.69 134.375L19.69 140.885L26.41 116.56L24.795 114.06C18.1923 103.562 14.6895 91.4119 14.69 79.01C14.69 42.71 44.405 13.1 80.965 13.1C98.675 13.1 115.29 20 127.79 32.5C133.956 38.593 138.848 45.8529 142.178 53.8563C145.509 61.8597 147.213 70.4462 147.19 79.115C147.14 115.39 117.45 145 80.94 145H80.915ZM137.295 22.995C122.085 8.305 102.085 0 80.81 0C36.93 0 1.2 35.575 1.175 79.295C1.175 93.255 4.82 106.875 11.8 118.93L0.5 160L42.74 148.985C54.4415 155.294 67.5211 158.612 80.815 158.645H80.84C124.745 158.645 160.475 123.07 160.5 79.32C160.5 58.15 152.245 38.225 137.195 23.25L137.295 22.995Z"
          fill="#CCEBD7"
        />
      </g>
      <defs>
        <clipPath>
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhatsappBannerIcon;
