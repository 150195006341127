import { INDIA_TIMEZONES } from './consts';

export const getCommunityBannerImageProps = (url) => {
  return {
    alt: 'community overview Image',
    desktopImgProps: {
      src: url,
      width: 129.85,
      height: 249.45
    },
    mobileImgProps: {
      src: url,
      width: 89.83,
      height: 172.56
    }
  };
};

export const nasIoCardTypes = {
  n: {
    borderTopLeftRadius: '400px',
    borderTopRightRadius: '400px',
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '40px',
    hoverColor: '#006782'
  },
  a: {
    borderTopLeftRadius: '400px',
    borderTopRightRadius: '400px',
    borderBottomLeftRadius: '400px',
    borderBottomRightRadius: '400px',
    hoverColor: '#FFA9C2'
  },
  s: {
    borderTopLeftRadius: '120px',
    borderTopRightRadius: '40px',
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '120px',
    hoverColor: '#7486FF'
  },
  i: {
    borderTopLeftRadius: '40px',
    borderTopRightRadius: '40px',
    borderBottomLeftRadius: '40px',
    borderBottomRightRadius: '40px',
    hoverColor: '#AEC26A'
  },
  o: {
    borderTopLeftRadius: '400px',
    borderTopRightRadius: '400px',
    borderBottomLeftRadius: '400px',
    borderBottomRightRadius: '400px',
    hoverColor: '#FFA9C2'
  }
};

export const checkIsUserFromIndia = () => {
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return INDIA_TIMEZONES.includes(currentTimeZone);
};
