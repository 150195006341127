import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import Text from '@/components/common/Text';
import Icon from '@/icons/index';
import { useRouter } from 'next/router';

const MonetizationSection = ({
  title,
  benefits,
  bottomBannerSrc,
  cta
}) => {
  const router = useRouter();

  const imageProps = {
    desktopImgProps: {
      src: bottomBannerSrc,
      layout: 'responsive',
      width: 1120,
      height: 481
    },
    mobileImgProps: {
      src: bottomBannerSrc,
      layout: 'responsive',
      width: 1120,
      height: 481
    }
  };

  return (
    <div className="flex items-center justify-center pb-40 mx-24 pt-80 md:mx-40">
      <div className="max-w-[1120px] w-full">
        <h2 className="px-24 mb-40 font-semibold text-heading-xl text-npl-text-icon-on-light-surface-primary md:px-40 md:text-center md:text-display-sm md:mb-44">
          {title}
        </h2>
        <div className="bg-[#FEFBF1] rounded-[34px]">
          <div className="flex flex-col justify-between px-32 py-20 space-x-0 space-y-48 md:py-48 md:px-60 md:flex-row md:space-y-0 md:space-x-32">
            {benefits.map((benefit) => (
              <div key={benefit.title}>
                <div className="mb-40">
                  <Icon
                    name={benefit.iconName}
                    width="56"
                    height="56"
                    fill="#EBB400"
                  />
                </div>
                <Text customClassName="font-semibold text-heading-sm">
                  {benefit.title}
                </Text>
                <Text customClassName="text-body-sm mt-8">
                  {benefit.description}
                </Text>
              </div>
            ))}
          </div>
          <div className="hidden md:block">
            <NextImage {...imageProps} />
          </div>
        </div>
        {cta && (
          <div className="flex justify-center mt-40">
            <RoundedButton
              displayType={cta?.type}
              onClick={() => {
                router.push(cta?.url);
              }}
              customClassNames="!h-[56px] !font-medium !text-button-lg w-full lg:w-auto"
              data-testid="homepage-community-categories-section-start-button">
              {cta?.text}
            </RoundedButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonetizationSection;
