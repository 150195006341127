import AdminAuthContextProvider, {
  useAdminAuthContext
} from '@/contexts/AdminAuthContext';
import {
  BRANDS_SECTION,
  COMMUNITY_TOOLS,
  INFO_WITH_GRAPHIC_LIST,
  META_DATA,
  MONEY_SLIDER,
  POS_CONS_SECTION,
  PRODUCT_WALKTHROUGH,
  START_COMMUNITY,
  TESTIMONIAL_V2,
  TEXT_BANNER_V2,
  TRAINERS,
  COMMUNITY_CATEGORIES,
  MONETIZATION_SECTION,
  FAQ_SECTION
} from './consts';
import React, { useEffect, useMemo } from 'react';
import {
  getQuerySearchParam,
  routeToStartCommunityForm
} from '@/utility/urlHelpers';
import {
  startCommunityForFreeButtonClick,
  trackGAEvent
} from '@/utility/analytics';

import { ACCESS_TOKEN_KEY } from '@/modules/AdminAuth';
import AdaptiveVideoModal from '@/components/common/AdaptiveVideoModal';
import AuthContextProvider from '@/contexts/AuthContext';
import BrandsHomeSection from '@/components/sections/BrandsHomeSection';
import BuildWithYou from './components/BuildWithYou';
import CommunityTools from './components/CommunityTools';
import { HOMEPAGE_CTA_CLICK_EVENT } from '@/utility/analyticsConsts';
import InfoWithGraphicList from './components/InfoWithGraphicList';
import MoneySliderSection from '@/components/sections/MoneySliderSection/MoneySliderSection';
import NavbarV2 from '@/components/features/NavbarV2';
import NewFooter from '@/components/common/NewFooterV2';
import PageMetaTags from '@/components/common/PageMetaTags';
import ProductWalkthrough from './components/ProductWalkthrough';
import ProsConsSection from './components/ProsConsSection';
import RoundedBackgroundCtaSection from '@/components/common/RoundedBackgroundCtaSection';
import TestimonialV2 from './components/TestimonialV2';
import TextBannerV2 from './components/TextBannerV2';
import { useRouter } from 'next/router';
import { useStartCta } from '@/hooks/useStartCta';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import CommunityCategories from '@/pages/home/components/CommunityCategories';
import Cookies from 'js-cookie';
import FAQSection from './components/FAQSection';
import MonetizationSection from './components/MonetizationSection';
import { checkIsUserFromIndia } from './utils';
import { CM_PORTAL_HOMEPAGE_ROUTE } from '@/utility/routesHelper';

const HomePage = (props) => {
  const { isDesktopView, isGtEqMd } = useWindowWidthContext();
  const [currentAdaptiveVideoData, setCurrentAdaptiveVideoData] =
    React.useState(null);
  const [showVideoModal, setShowVideoModal] = React.useState(false);
  const [activeTabId, setActiveTabId] = React.useState(0);
  const { isDemoUser, isAdmin } = useAdminAuthContext();
  const router = useRouter();
  const pathName = router.pathname;
  const isHomeRoute = useMemo(() => pathName === '/', [pathName]);

  //redirect to India's landing page when users are in India
  useEffect(() => {
    if (checkIsUserFromIndia() && router.isReady) {
      router.replace({ pathname: '/india', query: router.query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  const handleVideoModalClose = () => {
    setShowVideoModal(false);
    setCurrentAdaptiveVideoData(null);
  };

  const handleChangeCommunityCategoryTabClick = (id) => {
    setActiveTabId(id);
  };

  // SideEffect to redirect the user to the portal
  useEffect(() => {
    if (isAdmin && !isDemoUser) {
      const query = getQuerySearchParam('noRedirect');
      if (!query && Cookies.get(ACCESS_TOKEN_KEY)) {
        window.location.href = CM_PORTAL_HOMEPAGE_ROUTE;
      }
    }
  }, [isAdmin, isDemoUser]);

  const { label, link } = useStartCta();

  const handleStartCommunityClick = (section = '') => {
    trackGAEvent(HOMEPAGE_CTA_CLICK_EVENT, { sectionId: section });
    startCommunityForFreeButtonClick({
      page: 'homepage',
      section,
      time: new Date().getTime()
    });
    routeToStartCommunityForm(router, link);
  };

  const renderSections = () => {
    const sectionOrder = props.sectionOrder || Object.keys(props);
    return sectionOrder?.map((section) => {
      const isSectionHidden = !!props?.[section]?.isSectionHidden;
      if (isSectionHidden) return null;

      switch (section) {
        case META_DATA:
          return (
            <PageMetaTags
              key="page-meta-tags-section"
              {...props?.[section]}
            />
          );
        case TEXT_BANNER_V2: {
          return (
            <div
              id="home-text-banner-v2"
              key="homepage-text-banner-v2-section">
              <TextBannerV2 {...props?.[section]} />
            </div>
          );
        }
        case COMMUNITY_TOOLS: {
          return (
            <div
              id="home-community-tools"
              key="homepage-community-tools-section">
              <CommunityTools {...props?.[section]} isGtEqMd={isGtEqMd} />
            </div>
          );
        }
        case COMMUNITY_CATEGORIES:
          return (
            <div
              id="home-communities-carousel-section"
              key="homepage-communities-section">
              <CommunityCategories
                activeTabId={activeTabId}
                {...props?.[section]}
                onTabClick={handleChangeCommunityCategoryTabClick}
              />
            </div>
          );
        case POS_CONS_SECTION: {
          return (
            <div id="home-pros-cons" key="homepage-pros-cons-section">
              <ProsConsSection {...props?.[section]} />
            </div>
          );
        }
        case INFO_WITH_GRAPHIC_LIST:
          return (
            <div
              id="home-killer-features"
              key="homepage-info-with-graphic">
              <InfoWithGraphicList {...props?.[section]} />
            </div>
          );
        case MONETIZATION_SECTION:
          return (
            <div id="monetization-section" key="monetization-section">
              <MonetizationSection {...props?.[section]} />
            </div>
          );
        case PRODUCT_WALKTHROUGH:
          return (
            <div
              id="home-product-walkthrough"
              key="homepage-product-walkthrough">
              <ProductWalkthrough {...props?.[section]} />
            </div>
          );
        case TESTIMONIAL_V2:
          return (
            <div id="home-testimonial-v2" key="homepage-testimonial-v2">
              <TestimonialV2 {...props?.[section]} />
            </div>
          );
        case TRAINERS:
          return (
            <div id="home-trainers" key="homepage-trainers">
              <BuildWithYou {...props?.[section]} />
            </div>
          );
        case START_COMMUNITY:
          return (
            <div
              id="home-rounded-cta-section"
              key="homepage-start-community-section">
              <RoundedBackgroundCtaSection
                {...props?.[section]}
                ctaText={label}
                ctaHandler={() =>
                  handleStartCommunityClick('RoundedBackgroundCtaSection')
                }
              />
            </div>
          );
        case FAQ_SECTION:
          return (
            <div id="faq-section" key="faq-section">
              <FAQSection {...props?.[section]} />
            </div>
          );
        case BRANDS_SECTION:
          return (
            <div id="home-brands-section" key="homepage-brands-section">
              <BrandsHomeSection {...props?.[section]} />
            </div>
          );
        case MONEY_SLIDER:
          return (
            <div
              id="home-money-slider"
              key="homepage-money-slider-section">
              <MoneySliderSection {...props?.[section]} />
            </div>
          );
        default:
          return null;
      }
    });
  };

  return (
    <AuthContextProvider>
      <AdminAuthContextProvider>
        <div className="font-poppins">
          <NavbarV2
            showFullLogo
            showStartCommunity
            isHomeRoute={isHomeRoute}
          />
          {props.isProductHuntShown && (
            <div className="flex pl-24 mb-16 md:justify-center md:mb-0 md:pl-0">
              <a
                href={props.productHuntHref}
                target="_blank"
                rel="noreferrer">
                <img
                  src={props.productHuntImgSrc}
                  alt="Nas&#0046;io&#0032;for&#0032;WhatsApp&#0032;groups - Make&#0032;money&#0032;from&#0032;your&#0032;WhatsApp&#0032;chat&#0032;groups&#0032;and&#0032;communities | Product Hunt"
                  style={{ width: '250px', height: '54px' }}
                  width="250"
                  height="54"
                />
              </a>
            </div>
          )}
          <div>{renderSections()}</div>
          <NewFooter showNavLinks={true} />
          {currentAdaptiveVideoData && (
            <AdaptiveVideoModal
              isDesktopView={isDesktopView}
              adaptiveVideoData={currentAdaptiveVideoData}
              onModalClose={handleVideoModalClose}
              onVideoEnd={handleVideoModalClose}
              isVideoPlaying={showVideoModal}
              customClassName="overview-video"
            />
          )}
        </div>
      </AdminAuthContextProvider>
    </AuthContextProvider>
  );
};

export default HomePage;
