import AdaptiveVideoModal from '@/components/common/AdaptiveVideoModal';
import IconByName from '@/components/common/IconsV2/IconByName';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { trackGAEvent } from '@/utility/analytics';
import {
  HOMEPAGE_CTA_CLICK_EVENT,
  HOMEPAGE_WATCH_VIDEO_COMPLETED_EVENT,
  HOMEPAGE_WATCH_VIDEO_EVENT,
  PRODUCT_DEMO_CTA_CLICK_EVENT
} from '@/utility/analyticsConsts';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useCallback } from 'react';
import { TEXT_BANNER_V2 } from '../../consts';
import Text from '@/components/common/Text';

const TextBannerV2 = ({
  titleTop,
  titleBottom,
  animatingList,
  description,
  primaryCta,
  secondaryCta,
  imageData,
  desktopImageData,
  videoSrc
}) => {
  const router = useRouter();

  const animatingListRef = useRef(null);
  const animatingListWrapperRef = useRef(null);
  const currentAnimatingTextIndex = useRef(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const animateTextElements = useCallback(() => {
    try {
      const totalListSize = animatingList.length;
      const itemTranslateHeight = parseFloat(
        window
          .getComputedStyle(animatingListWrapperRef.current, null)
          .getPropertyValue('height')
      );

      if (currentAnimatingTextIndex.current === totalListSize) {
        animatingListRef.current.style.transform = `translateY(0)`;

        currentAnimatingTextIndex.current = 1;
      } else {
        animatingListRef.current.style.transform = `translateY(-${
          (itemTranslateHeight + 4) * currentAnimatingTextIndex.current
        }px)`;

        currentAnimatingTextIndex.current =
          currentAnimatingTextIndex.current + 1;
      }
    } catch (e) {
      console.error(e);
    }
  }, [animatingList.length]);

  const getAnimatingTextElements = () => {
    return (
      <div
        className="space-y-4"
        style={{
          transition: 'all 0.3s linear'
        }}
        ref={animatingListRef}>
        {animatingList.map((item, index) => (
          <p
            className="py-4 md:text-center"
            key={index}
            style={{ color: item.textColor }}>
            {item.text}
          </p>
        ))}
      </div>
    );
  };

  useEffect(() => {
    let timer;

    if (animatingListRef.current)
      timer = setInterval(animateTextElements, 1500);

    return () => clearInterval(timer);
  }, [animateTextElements]);

  return (
    <div className="pb-40 md:p-40">
      <div className="px-24 mb-40 md:px-0 md:mb-32 lg:px-40 lg:max-w-[800px] lg:mx-auto">
        <div className="text-[#1b1b1b] text-display-xs font-semibold mb-24 md:text-display-md md:text-center">
          <h2>
            <Text text={titleTop} />
          </h2>
          <div
            className="flex items-center space-x-12 md:justify-center"
            data-testid="homepage-banner-title">
            <div
              className="h-[56px] overflow-y-hidden md:h-[72px] md:text-left"
              ref={animatingListWrapperRef}>
              {getAnimatingTextElements()}
            </div>
          </div>
          <h2>{titleBottom}</h2>
        </div>
        <div className="mb-40 md:text-center">
          <p className="text-[#1b1b1b] text-body-lg opacity-65">
            {description}
          </p>
        </div>
        <div className="flex flex-col space-y-12 md:space-y-0 md:flex-row md:space-x-12 md:justify-center">
          <RoundedButton
            data-testid="homepage-banner-primary-button"
            displayType={primaryCta.type}
            onClick={() => {
              trackGAEvent(HOMEPAGE_CTA_CLICK_EVENT, {
                sectionId: TEXT_BANNER_V2
              });
              router.push(primaryCta.url);
            }}
            customClassNames="!h-[56px] !font-medium !text-button-lg text-[#1b1b1b] w-full flex justify-center md:w-fit">
            {primaryCta.text}
          </RoundedButton>
          {!secondaryCta.isHidden && (
            <RoundedButton
              data-testid="homepage-banner-secondary-button"
              displayType={secondaryCta.type}
              onClick={() => {
                trackGAEvent(PRODUCT_DEMO_CTA_CLICK_EVENT, {
                  sectionId: TEXT_BANNER_V2
                });
                router.push(secondaryCta.url);
              }}
              customClassNames="!h-[56px] !font-medium !text-button-lg text-[#1b1b1b] w-full flex justify-center md:w-fit">
              {secondaryCta.text}
            </RoundedButton>
          )}
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsModalOpen(true);
          trackGAEvent(HOMEPAGE_WATCH_VIDEO_EVENT);
        }}
        className="relative block md:hidden">
        <NextImage {...imageData} />
        <div className="absolute flex items-center justify-center w-64 h-64 -translate-x-1/2 -translate-y-1/2 rounded-full bg-dark-50 top-1/2 left-1/2">
          <IconByName name="play" />
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        onClick={() => {
          setIsModalOpen(true);
          trackGAEvent(HOMEPAGE_WATCH_VIDEO_EVENT);
        }}
        className="relative hidden md:max-w-[1120px] md:mx-auto md:block">
        <NextImage {...desktopImageData.default} />
        <div className="absolute inset-0 duration-300 opacity-0 hover:opacity-100">
          <NextImage {...desktopImageData.hover} />
        </div>
      </div>
      <AdaptiveVideoModal
        adaptiveVideoData={{
          videoAssets: {
            mp4: videoSrc
          }
        }}
        onModalClose={() => setIsModalOpen(false)}
        onVideoEnd={() => {
          setIsModalOpen(false);
          trackGAEvent(HOMEPAGE_WATCH_VIDEO_COMPLETED_EVENT);
        }}
        isVideoPlaying={isModalOpen}
        customClassName="md:!box-content md:w-[90vw] md:max-w-[800px]"
        showCloseIcon={false}
      />
    </div>
  );
};

export default TextBannerV2;
