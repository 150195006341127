import React, { useCallback } from 'react';
import { getPlatformDetails } from '@/features/community/utils';
import Users from '@/components/common/IconsV2/Users';
import Tag from '@/components/common/IconsV2/Tag';
import NextImage from '@/components/common/NextImage';
import config from '@/utility/config';
import { formatNumber } from '@/utility/helpers';
import Icon from '@/icons/index';

const CommunityStoryCard = ({
  platformName,
  managerImg,
  title,
  priceText,
  memberCount,
  testimonial,
  link
}) => {
  const getPlatformIcon = useCallback(() => {
    return getPlatformDetails(platformName)?.getIcon({
      customClassNames: 'w-24 h-24',
      width: 24,
      height: 24
    });
  }, [platformName]);

  return (
    <div className="p-24 font-poppins rounded-28 relative cursor-pointer border-1 border-npl-transparent-black-10 min-w-[320px] group bg-white-default hover:bg-npl-neutral-light-2 md:min-w-[340px] lg:shadow-npl-styles-shadow-01 lg:w-full lg:min-w-[0px] lg:max-w-[360px]">
      <a
        href={`${link}/?utm_source=${config.siteBaseUrl}`}
        target="_blank"
        rel="noreferrer">
        <div className="flex flex-col justify-start">
          <div className="flex flex-col items-start">
            <div className="flex justify-between w-full">
              <div className="rounded-12 mb-16 shrink-0">
                <NextImage {...managerImg} className="rounded-50" />
              </div>
              <div className="hidden group-hover:block">
                <Icon name="arrow-narrow-up-right" />
              </div>
            </div>
            <div className="w-full mb-12 font-semibold break-words text-heading-md text-npl-text-icon-on-light-surface-primary">
              {title}
            </div>
            {platformName && (
              <div className="flex flex-row items-center mb-16">
                <div className="w-24 h-24 mr-8">{getPlatformIcon()}</div>
                <div className="font-medium text-label-md text-npl-text-icon-on-light-surface-secondary">
                  On {getPlatformDetails(platformName)?.overviewText}
                </div>
              </div>
            )}
            <div className="flex flex-row items-center mb-16">
              <Users />
              <div className="ml-8 font-medium text-label-md text-npl-text-icon-on-light-surface-secondary">
                {formatNumber(parseInt(memberCount))} members
              </div>
            </div>
            <div className="flex flex-col mb-32">
              <div className="flex flex-row items-center">
                <Tag />
                <div className="ml-8 font-medium text-label-md text-npl-text-icon-on-light-surface-secondary ">
                  {priceText}
                </div>
              </div>
            </div>
            <div className="h-[1px] w-full bg-npl-neutral-light-6" />
            <div className="flex mt-32">
              <div className="text-body-sm text-npl-text-icon-on-light-surface-primary">
                {testimonial}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default CommunityStoryCard;
