// export const sleep = (ms) => {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, ms);
//   });
// };
import {
  SHORT_DESKTOP_TEXT_NUMBER_OF_CHARACTERS,
  SHORT_MOBILE_TEXT_NUMBER_OF_CHARACTERS
} from '@/utility/courses/constants';

export const ratingType = {
  FULL: 'full',
  PARTIAL: 'partial',
  EMPTY: 'empty'
};

export const createRatingArray = ({ total, rating }) => {
  try {
    let full = Math.floor(rating);
    let partial = Math.ceil(rating % 1);
    let remaining = Math.floor(total - rating);

    let fullRatingArray = Array(full).fill(ratingType.FULL);
    let partialRatingArray = Array(partial).fill(ratingType.PARTIAL);
    let remainingRatingArray = Array(remaining).fill(ratingType.EMPTY);

    return [
      ...fullRatingArray,
      ...partialRatingArray,
      ...remainingRatingArray
    ];
  } catch (e) {
    //Rating should be less than or equal to total & Can only be numbers
    throw new Error('StarRatings: Invalid Rating params');
  }
};

export const convertCentsToDollars = (cents) => {
  if (isNaN(cents)) {
    return '';
  }
  try {
    cents = (cents + '').replace(/[^\d.-]/g, '');
    cents = parseFloat(cents);
    return cents / 100;
  } catch {
    return '';
  }
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const courseScheduleExists = (countdown) => {
  const { years, days, hrs, mins } = countdown;
  const noSchedule = !years && !days && !hrs && !mins;

  return !noSchedule;
};

export const sortAscending = (list) => list?.sort((a, b) => a - b);

// USD 1,000
export const convertCurrencyAndFormat = ({
  currency,
  conversionMultiplier,
  price
}) => {
  try {
    const convertedPrice = (price * conversionMultiplier)?.toFixed();
    const formattedPrice = formatNumber(convertedPrice);

    if (currency) return `${currency} ${formattedPrice}`;
    else return formattedPrice;
  } catch {
    return `USD ${price}`;
  }
};

// 100000 -> 100,000
// 100000.0322 -> 100,000.03
export const formatNumber = (price, isCentFormat) => {
  try {
    if (!price) return 0;

    return new Intl.NumberFormat().format(
      (isCentFormat ? convertCentsToDollars(price) : price).toFixed(2)
    );
  } catch {
    return price;
  }
};

export const getFormattedPrice = ({ price, currency }) => {
  if (!currency || price === undefined || price === null) return null;

  const formattedPrice =
    typeof price === 'number' ? price.toLocaleString() : price;

  return `${currency} ${formattedPrice}`;
};

export const isTextDescriptionShort = (description, isDesktopView) => {
  if (!description) return false;

  const lengthBoundary = isDesktopView
    ? SHORT_DESKTOP_TEXT_NUMBER_OF_CHARACTERS
    : SHORT_MOBILE_TEXT_NUMBER_OF_CHARACTERS;

  const descriptionLength = description.reduce(
    (acc, curr) => (acc += curr?.text.length),
    0
  );

  return descriptionLength <= lengthBoundary;
};

export const downloadFile = ({ url, filename = 'nasAcademyAsset' }) => {
  try {
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click'));
    document.body.removeChild(link);
  } catch (e) {
    console.error(e);
  }
};

export const isEvenNumber = (num) => num % 2 === 0;

export const doesValueExist = (value) => {
  return value !== undefined && value !== null;
};

export const delay = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

export function downloadCSV(csvStr, name = 'output') {
  var hiddenElement = document.createElement('a');
  hiddenElement.href =
    'data:text/csv;charset=utf-8,' + encodeURIComponent(csvStr);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${name}.csv`;
  hiddenElement.click();
}

export function isLiteralObject(data) {
  return !!data && data.constructor === Object;
}
