import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import style from './rangeSeekBar.module.scss';
const RangeSeekBar = ({
  value,
  onChange,
  options,
  min,
  max,
  step,
  classNameForInput
}) => {
  // set css variable color for the input range
  useEffect(() => {
    // document.style?.setProperty('--lower-color', '#FF0000');
    // set css variable color to be #fff
  }, []);
  return (
    <div className={style['c-RangeSeekBar']}>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        step={step}
        className={'mt-24 ' + classNameForInput}
        list="expectedValueSteps"
      />
      <datalist id="expectedValueSteps">
        {options.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </datalist>
    </div>
  );
};

RangeSeekBar.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.array,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};
RangeSeekBar.defaultProps = {
  value: 0,
  onChange: () => {},
  options: [20, 50, 100, 500, 1000, 10000]
};
export default RangeSeekBar;
