import AdaptiveVideoModal from '@/components/common/AdaptiveVideoModal';
import IconByName from '@/components/common/IconsV2/IconByName';
import NextImage from '@/components/common/NextImage';
import RoundedButton from '@/components/common/RoundedButton';
import { trackGAEvent } from '@/utility/analytics';
import {
  HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_COMPLETED_EVENT,
  HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_EVENT
} from '@/utility/analyticsConsts';
import React, { useState } from 'react';

const ProductWalkthrough = ({ text, ctaText, cardList, videoSrc }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="py-64 md:py-[80px] md:px-[40px]">
      <div className="mb-[40px] px-16 md:px-0">
        <h2 className="text-heading-xl text-[#1b1b1b] text-32 font-semibold mb-24 md:text-display-sm md:text-center md:mb-[40px] md:max-w-[620px] md:mx-auto">
          {text}
        </h2>
        <RoundedButton
          displayType="neutral"
          onClick={() => {
            trackGAEvent(HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_EVENT);
            setIsModalOpen(true);
          }}
          customClassNames="bg-npl-neutral-dark-3 text-npl-text-icon-on-dark-primary !h-[56px] !font-medium w-full flex justify-center md:w-fit md:mx-auto"
          data-testid="homepage-product-walkthrough-button">
          <div className="flex items-center justify-center space-x-8">
            <IconByName name="play" />
            <span>{ctaText}</span>
          </div>
        </RoundedButton>
      </div>
      <div className="space-y-[48px] md:space-y-[40px] lg:space-y-0">
        {cardList.map((card, index) => {
          const {
            eyebrow,
            title,
            description,
            titleColor,
            imageData,
            ltr
          } = card;

          const directionClassName = ltr
            ? 'md:flex-row'
            : 'md:flex-row-reverse';

          const spacingClassName = ltr
            ? 'md:mr-40 md:mr-80'
            : 'md:ml-40 md:ml-80';

          return (
            <div
              key={index}
              className={`flex flex-col md:items-center ${directionClassName} xl:max-w-[1040px] xl:mx-auto`}>
              <div
                className={`p-24 pb-32 md:p-0 md:pb-0 ${spacingClassName}`}>
                <p className="uppercase text-overline-sm text-[#1b1b1b] opacity-65 mb-8 font-medium">
                  {eyebrow}
                </p>
                <h2
                  style={{ color: titleColor }}
                  className="text-display-xs font-semibold mb-16 md:text-display-sm">
                  {title}
                </h2>
                <p className="text-body-md text-[#1b1b1b] opacity-65 ml-24 md:text-body-sm lg:text-body-md">
                  {description}
                </p>
              </div>
              <div className="md:min-w-[324px] lg:min-w-[416px] xl:min-w-[480px]">
                <NextImage {...imageData} />
              </div>
            </div>
          );
        })}
      </div>
      <AdaptiveVideoModal
        adaptiveVideoData={{
          videoAssets: {
            mp4: videoSrc
          }
        }}
        onModalClose={() => setIsModalOpen(false)}
        onVideoEnd={() => {
          trackGAEvent(HOMEPAGE_WATCH_PRODUCT_DEMO_VIDEO_COMPLETED_EVENT);
          setIsModalOpen(false);
        }}
        isVideoPlaying={isModalOpen}
        customClassName="md:!box-content md:w-[90vw] md:max-w-[800px]"
        showCloseIcon={false}
      />
    </div>
  );
};

export default ProductWalkthrough;
