import Head from 'next/head';
import React from 'react';

import config from '@/utility/config';

const PageMetaTags = ({
  title,
  description,
  url,
  canonicalUrl,
  imageUrl,
  addRobot,
  robotContentIndexed,
  children
}) => {
  return (
    <Head>
      <meta property="twitter:card" content="summary_large_image" />

      {/* Title */}
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />

      {/* Image */}
      <meta name="image" property="og:image" content={imageUrl} />
      <meta property="twitter:image" content={imageUrl} />

      {/* Description */}
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />

      {/* Url */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="twitter:url" content={url} />
      <link rel="canonical" href={canonicalUrl ? canonicalUrl : url} />

      {/* Robot */}
      {addRobot && (
        <meta
          name="robots"
          content={`${
            robotContentIndexed ? 'index' : 'noindex'
          }, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
        />
      )}

      {/* Others */}
      {children}
    </Head>
  );
};

PageMetaTags.defaultProps = {
  title: 'Learn directly from excellent teachers | Nas Academy',
  description:
    'Our classes are highly engaging, super energetic, and straight to the point. You have your own learning style. Take the quiz and find the best course for you.',
  url: config.siteBaseUrl,
  imageUrl:
    'https://d2oi1rqwb0pj00.cloudfront.net/ssr-academy/metatagImages/jpg/defaultHomeWithLogo.jpg',
  addRobot: true,
  robotContentIndexed: true
};

export default PageMetaTags;
