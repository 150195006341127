import config from '@/utility/config';

const imgBasePath = config.imagesBasePath;

export const defaultLiveSessionsTitle = [{ text: 'Live Sessions' }];
export const waitListTitle = {
  title: 'Be the first in line',
  description: 'Get an exclusive email as soon as the course is out.'
};
export const defaultLiveSessionsDescription = [
  {
    text: `In addition to the course videos, you would also get a cohort-based live learning experience with recordings. `
  }
];
export const defaultLiveSessionsPointsTitle = [
  { text: 'You Will Get:', isBolder: true }
];

export const defaultLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/liveSession800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const defaultLiveSessionsGif = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/Spotlight/spotlight-lg-classmate.gif`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const defaultLiveSessionsVideo = {
  alt: 'live session',
  mobileVidProps: {
    srcMap: [
      {
        src: `${imgBasePath}/ssr-academy/common/mp4/nas-academy-Live-learning.mp4`,
        type: 'video/mp4'
      }
    ],
    width: 328,
    height: 184,
    autoPlay: true,
    playsInline: true,
    checkIsInView: false,
    loop: true,
    muted: true
  },
  desktopVidProps: {
    srcMap: [
      {
        src: `${imgBasePath}/ssr-academy/common/mp4/nas-academy-Live-learning.mp4`,
        type: 'video/mp4'
      }
    ],
    width: 400,
    height: 260,
    autoPlay: true,
    playsInline: true,
    checkIsInView: false,
    loop: true,
    muted: true
  }
};

export const defaultCommunityVideo = {
  alt: 'community video',
  mobileVidProps: {
    srcMap: [
      {
        src: `${imgBasePath}/ssr-academy/common/mp4/nas-academy-Community-connect.mp4`,
        type: 'video/mp4'
      }
    ],
    width: 328,
    height: 184,
    autoPlay: true,
    playsInline: true,
    checkIsInView: false,
    loop: true,
    muted: true
  },
  desktopVidProps: {
    srcMap: [
      {
        src: `${imgBasePath}/ssr-academy/common/mp4/nas-academy-Community-connect.mp4`,
        type: 'video/mp4'
      }
    ],
    width: 400,
    height: 260,
    autoPlay: true,
    playsInline: true,
    checkIsInView: false,
    loop: true,
    muted: true
  }
};

export const nasdailyCommunityImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/community800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const dearAlyneLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/dearAlyneLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const projectNightfallLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/unstopableConfidenceLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const metakovanLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/metakovanLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const mingLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/mingLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const smileSquadLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/smileSquadLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const chinkeeTanLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/chinkeeLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const whangOdLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/whangLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const anthonyLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/anthonyLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const mavLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/mavLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const jasonLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/jasonLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const erwanLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/erwanLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const carloLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/carloLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const riteshLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/riteshLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const jamesDeakinLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/jamesLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const catrionaGrayLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/catrionaLiveSessions800x520.png`,
    layout: 'responsive',
    width: 400,
    height: 260
  }
};

export const catrionaGrayCommunityImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/na-website/CatrionaGrayPage/jpg/catrionaCommunity376x217.jpg`,
    layout: 'responsive',
    width: 376,
    height: 211
  }
};

export const danMaceLiveSessionsImg = {
  alt: 'Image',
  mobileImgProps: {
    src: `${imgBasePath}/ssr-academy/common/png/danMaceLiveSessions800x520.png`,
    layout: 'responsive',
    width: 376,
    height: 211
  }
};

export const defaultCommunityCardProps = {
  title: [{ text: 'community' }],
  description: [
    {
      text: 'Your learning doesn’t end after the course ends. Work on your plans, discuss with your classmates and get real-time feedback from qualified trainers.'
    }
  ],
  pointsTitle: [{ text: 'What Is In For You:', isBolder: true }],
  infoItems: [
    {
      icon: 'whatsapp-stroked',
      text: [
        {
          text: 'Enroll in an exclusive WhatsApp group with a dedicated trainer'
        }
      ]
    },
    {
      icon: 'facebook-dark',
      text: [
        {
          text: 'Exclusive Facebook group where there are regular contests and activities'
        }
      ]
    },
    {
      icon: 'messaging-dark',
      text: [
        {
          text: 'Members-only special discounts and access to private events'
        }
      ]
    }
  ]
};

export const SHORT_MOBILE_TEXT_NUMBER_OF_CHARACTERS = 500;
export const SHORT_DESKTOP_TEXT_NUMBER_OF_CHARACTERS = 650;

export const ACCESS_CTA_DEFAULT_TEXT = 'Access Course';
export const WAITLIST_CTA_DEFAULT_TEXT = 'Join Waitlist';
export const WAITLIST_JOINED_CTA_DEFAULT_TEXT = 'On Waitlist';

// SECTION PROPS VARIABLES
export const USER_FEEDBACK_PROPS = 'userFeedbackProps';
export const DISCOVER_MORE_COURSES_PROPS = 'discoverMoreCoursesProps';
