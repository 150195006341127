import { convertQueryStringToJson } from './stringHelper';

export const getQuerySearchParam = (key) => {
  const queryParamsObj = {};
  if (typeof window === 'undefined') {
    return null;
  }
  window.location.search
    .substring(1)
    .split('&')
    .forEach((item) => {
      const [key, value] = item.split('=');
      queryParamsObj[key] = value;
    });

  return queryParamsObj[key];
};

export const getQueryParams = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  const queryStr = window.location.search || '';
  const queryParamsJson = convertQueryStringToJson(queryStr);
  return queryParamsJson;
};
export const resetQueryParams = () => {
  if (typeof window === 'undefined') {
    return null;
  }
  window.history.pushState(
    {
      path: window.location.origin + window.location.pathname
    },
    '',
    window.location.origin + window.location.pathname
  );
};
export const addPrefixToUrlIfRequired = (url) => {
  try {
    if (url.startsWith('https') || url.startsWith('http')) return url;

    return `https://${url}`;
  } catch (e) {
    console.error(e);
    return url;
  }
};

export const routeToStartCommunityForm = (
  router,
  link = '/start-community'
) => {
  router.push({
    pathname: link,
    query: { fromPath: router?.asPath }
  });
};

export const routeToStartWhatsappCommunity = (
  router,
  link = '/start-whatsapp-community'
) => {
  router.push({
    pathname: link,
    query: { fromPath: router?.asPath }
  });
};

export const buildUrlWithQueryParams = (url, params) => {
  const entries = Object.entries(params);
  if (!params || !entries.length) {
    return url;
  }

  const filteredEntries = entries.filter(([_, value]) => value);

  if (!filteredEntries.length) {
    return url;
  }

  const filteredParams = Object.fromEntries(filteredEntries);

  return `${url}?${new URLSearchParams(filteredParams).toString()}`;
};
