import IconByName from '@/components/common/IconsV2/IconByName';
import RoundedButton from '@/components/common/RoundedButton';
import { trackGAEvent } from '@/utility/analytics';
import { formatNumber } from '@/utility/helpers';
import React, { useState } from 'react';
import { useEffect } from 'react';
import RangeSeekBar from '../../common/RangeSeekBar/RangeSeekBar';

const MoneySliderSection = ({
  title,
  subtitle,
  typesOfWaysToEarn,
  currency,
  defaultPosition = 0,
  totalSeekbarPosition = 0,
  FOLLOWER_COUNT,
  MONTHLY_RECURRING_REVENUE,
  MONTHLY_EVENTS_SALES_REVENUE,
  MONTHLY_CONTENT_SALES_REVENUE,
  MONTHLY_BRAND_SPONSORSHIPS
}) => {
  const {
    MONTHLY_MEMBERSHIP,
    PAID_EVENTS,
    BRAND_SPONSORSHIP,
    SELL_CONTENT
  } = typesOfWaysToEarn;
  const [selectedComponents, setSelectionComponents] = useState([
    MONTHLY_MEMBERSHIP,
    PAID_EVENTS,
    BRAND_SPONSORSHIP
  ]);

  const seekBarValuesArray = Array.from(
    Array(totalSeekbarPosition).keys()
  );
  const [seekBarValue, setSeekBarValue] = useState(defaultPosition);
  const [earningRevenuePrice, setEarningRevenuePrice] = useState(0);

  function getStylingForSelectedComponent(type) {
    if (selectedComponents.includes(type)) {
      return '!bg-npl-neutral-light-4 !border-npl-neutral-light-12 !border-2 !w-full justify-center';
    } else {
      return '!bg-[#fff] !w-full justify-center';
    }
  }

  function onClickForSelectedComponent(type) {
    // add the type to the selectedComponents array
    // if the type is already in the array, remove it
    if (selectedComponents.includes(type)) {
      trackGAEvent(`homepage-${type}-click-unselected`);
      setSelectionComponents(
        selectedComponents.filter((item) => item !== type)
      );
    } else {
      trackGAEvent(`homepage-${type}-click-selected`);
      setSelectionComponents([...selectedComponents, type]);
    }
  }

  function onChangeListenerForSeekBar(value) {
    if (value.target.value < 1) {
      return;
    }
    setSeekBarValue(value.target.value);
  }
  function getFollowers() {
    return formatNumber(FOLLOWER_COUNT[seekBarValue]);
  }

  useEffect(() => {
    // calculate the earning revenue price
    let earningRevenuePrice = 0;
    selectedComponents.forEach((component) => {
      switch (component) {
        case MONTHLY_MEMBERSHIP:
          earningRevenuePrice += MONTHLY_RECURRING_REVENUE[seekBarValue];
          break;
        case SELL_CONTENT:
          earningRevenuePrice +=
            MONTHLY_CONTENT_SALES_REVENUE[seekBarValue];
          break;
        case PAID_EVENTS:
          earningRevenuePrice +=
            MONTHLY_EVENTS_SALES_REVENUE[seekBarValue];
          break;
        case BRAND_SPONSORSHIP:
          earningRevenuePrice += MONTHLY_BRAND_SPONSORSHIPS[seekBarValue];
          break;
        default:
          break;
      }
    });
    setEarningRevenuePrice(earningRevenuePrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seekBarValue, selectedComponents]);

  return (
    <div className="bg-gradient-to-b to-[#FFFAE8] from-[#ffffff] lg:px-[120px] md:px-[40px] px-24 py-80">
      <div className="max-w-[960px] w-full mx-auto ">
        {title && (
          <h2 className="font-semibold text-heading-xl text-npl-text-icon-on-light-surface-primary md:text-center md:text-display-sm">
            {title}
          </h2>
        )}
        <div className="flex md:flex-row flex-col lg:space-x-[95px] md:space-x-[60px] space-x-0 md:space-y-[0px] space-y-[40px] mt-40">
          <div className="flex flex-col lg:max-w-[520px] max-w-full w-full">
            <span className="font-medium text-label-lg">{subtitle}</span>
            <span className="mt-16 font-medium text-heading-lg">
              {getFollowers()}
            </span>
            <div className="mt-8">
              <RangeSeekBar
                classNameForInput="bg-transparent"
                value={seekBarValue}
                onChange={onChangeListenerForSeekBar}
                options={seekBarValuesArray}
                min={seekBarValuesArray[0]}
                max={seekBarValuesArray[seekBarValuesArray.length - 1]}
                step={1}
              />
            </div>
            <div className="mt-40">
              <span className="font-medium text-label-lg">
                Choose how to monetize
              </span>
            </div>
            <div className="flex flex-row justify-between mt-16 space-x-8 ">
              <RoundedButton
                onClick={() =>
                  onClickForSelectedComponent(MONTHLY_MEMBERSHIP)
                }
                displayType={'outline'}
                customClassNames={
                  getStylingForSelectedComponent(MONTHLY_MEMBERSHIP) +
                  ' !text-12'
                }>
                <div className="flex flex-row space-x-0 whitespace-nowrap md:space-x-8">
                  <span className="hidden md:block">
                    <IconByName name="users-money" fill={'#000'} />
                  </span>
                  <span className="whitespace-nowrap">
                    Monthly membership
                  </span>
                </div>
              </RoundedButton>
              <RoundedButton
                onClick={() => onClickForSelectedComponent(SELL_CONTENT)}
                displayType={'outline'}
                customClassNames={
                  getStylingForSelectedComponent(SELL_CONTENT) +
                  ' !text-12'
                }>
                <div className="flex flex-row space-x-0 md:space-x-8">
                  <span className="hidden md:block">
                    <IconByName name="courses" />
                  </span>
                  <span className="whitespace-nowrap"> Sell content </span>
                </div>
              </RoundedButton>
            </div>
            <div className="flex flex-row justify-between mt-16 space-x-8 ">
              <RoundedButton
                onClick={() => onClickForSelectedComponent(PAID_EVENTS)}
                displayType={'outline'}
                customClassNames={
                  getStylingForSelectedComponent(PAID_EVENTS) + ' !text-12'
                }>
                <div className="flex flex-row space-x-0 md:space-x-8">
                  <span className="hidden md:block">
                    <IconByName name="calendar-date" />
                  </span>{' '}
                  <span className="whitespace-nowrap">Paid events</span>
                </div>
              </RoundedButton>
              <RoundedButton
                onClick={() =>
                  onClickForSelectedComponent(BRAND_SPONSORSHIP)
                }
                displayType={'outline'}
                customClassNames={
                  getStylingForSelectedComponent(BRAND_SPONSORSHIP) +
                  ' !text-12'
                }>
                <div className="flex flex-row space-x-0 md:space-x-8">
                  <span className="hidden md:block">
                    {' '}
                    <IconByName
                      name="heart-hand"
                      customClassNames={'fill-[#000] !w-[15px] !h-[15px] '}
                    />
                  </span>
                  <span className="whitespace-nowrap">
                    Brand sponsorship
                  </span>
                </div>
              </RoundedButton>
            </div>
          </div>

          <div className="flex flex-col md:max-w-[370px] bg-white-default max-w-full self-center w-full py-[50px] px-16 text-center rounded-28 border-1 border-npl-yellow-light-6 max-h-[200px] h-full shadow-npl-styles-shadow-01">
            <span className="font-medium text-label-lg ">Earn up to</span>
            <span className="mt-8 font-semibold text-heading-xl text-npl-yellow-light-11">
              <span className="text-heading-md">{currency ?? 'USD'}</span>{' '}
              {formatNumber(earningRevenuePrice * 12)}/year
            </span>
            <span className="mt-16 font-normal text-label-sm text-npl-text-icon-on-light-surface-tertiary/50">
              Based on Nas.io communities
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoneySliderSection;
