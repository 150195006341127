import InfoWithGraphic from '@/components/sections/InfoWithGraphic';
import React from 'react';

const InfoWithGraphicList = ({ list, title, backgroundColor }) => {
  return (
    <div
      style={{ backgroundColor }}
      className="px-24 max-w-[1200px] mx-auto md:px-40 lg:px-[80px]">
      <h2
        className="pb-16 -mx-8 text-heading-xl font-semibold text-[#1b1b1b] md:text-display-sm md:px-40 md:text-center"
        data-testid="homepage-info-graphic-list-title">
        {title}
      </h2>
      <div>
        {list.map((item, index) => (
          <InfoWithGraphic {...item} key={index} />
        ))}
      </div>
    </div>
  );
};

export default InfoWithGraphicList;
