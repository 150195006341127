import RoundedButton from '@/components/common/RoundedButton';
import React from 'react';
// import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { Player } from '@lottiefiles/react-lottie-player';
import { useRouter } from 'next/router';
import NextImage from '@/components/common/NextImage';
import IconByName from '@/components/common/IconsV2/IconByName';
import style from './InfoWithGraphic.module.scss';
import { HOMEPAGE_LEARN_MORE_EVENT } from '@/utility/analyticsConsts';
import { trackGAEvent } from '@/utility/analytics';

const DIRECTION = {
  LTR: 'ltr',
  RTL: 'rtl'
};

const GRAPHIC_TYPE = {
  MOTION_GRAPHIC: 'motion_graphic',
  IMAGE: 'image'
};

const MOTION_DATA = {
  monetise: '/animation/home_monetise.json',
  integration: '/animation/home_integration.json',
  'magic-reach': '/animation/home_magic_reach.json',
  events: '/animation/home_events.json',
  launch: '/animation/home_launch.json',
  'events-and-courses': '/animation/home_events_and_courses.json',
  'chat-connection': '/animation/chat-connection.json'
};

const BORDER_TYPE = {
  image_normal: 'image_normal',
  motion_normal: 'motion_normal'
};

const InfoWithGraphic = ({
  direction,
  eyebrow,
  eyebrowBg,
  title,
  description,
  ctaText,
  isFullSizeButtonInMobile,
  ctaType,
  onCtaClick,
  isRedirectType,
  redirectUrl,
  openInNewTab,
  graphicType,
  graphicProps,
  graphicBorder,
  iconEyebrow,
  showRedirectIcon = true
}) => {
  const router = useRouter();
  const directionClassName =
    direction === DIRECTION.LTR ? 'md:flex-row-reverse' : 'md:flex-row';

  const graphicBorderClassName =
    graphicBorder === BORDER_TYPE.image_normal
      ? style.imageBorder
      : style.graphicBorder;

  const onClick = () => {
    trackGAEvent(HOMEPAGE_LEARN_MORE_EVENT);

    if (isRedirectType) {
      router.push(redirectUrl);
      return;
    }

    if (openInNewTab && redirectUrl) {
      window.open(redirectUrl, '_blank');
      return;
    }

    onCtaClick?.();
  };

  const renderGraphic = () => {
    switch (graphicType) {
      case GRAPHIC_TYPE.MOTION_GRAPHIC:
        return (
          <Player
            src={MOTION_DATA?.[graphicProps?.motion]}
            autoplay
            loop
          />
        );
      case GRAPHIC_TYPE.IMAGE:
        return <NextImage {...graphicProps} />;
    }
  };

  return (
    <div className="max-w-[1440px] mx-auto">
      <div
        className={`py-40 md:py-[80px] md:flex ${directionClassName} md:justify-between md:items-center xl:max-w-[1200px] xl:mx-auto 2xl:py-[75px]`}>
        <div
          className={`${graphicBorderClassName} w-full mb-40 overflow-hidden flex-1 max-w-[324px] mx-auto md:max-w-none xl:min-w-[540px] ${
            direction === DIRECTION.RTL
              ? 'md:mr-[40px] lg:mr-[80px]'
              : 'md:ml-[40px] lg:ml-[80px]'
          }`}>
          {renderGraphic()}
        </div>
        <div className="flex-1">
          {!!eyebrow && (
            <div
              className="px-16 py-8 mb-32 uppercase rounded-full w-fit"
              style={{ background: eyebrowBg }}>
              <p className="font-medium text-16 text">{eyebrow}</p>
            </div>
          )}
          {iconEyebrow && (
            <div className="flex items-center mb-32 space-x-8">
              <div
                style={{ background: iconEyebrow.bgColor }}
                className="p-[6px] rounded-8">
                <IconByName
                  name={iconEyebrow.icon}
                  fill={iconEyebrow.textColor}
                />
              </div>
              <p
                style={{ color: iconEyebrow.textColor }}
                className="font-semibold text-label-lg">
                {iconEyebrow.text}
              </p>
            </div>
          )}
          <h3 className="text-heading-lg text-[#1b1b1b] font-semibold mb-16">
            {title}
          </h3>
          <p className="text-body-md text-[#1b1b1b] opacity-65">
            {description}
          </p>
          {ctaText && (
            <RoundedButton
              displayType={ctaType}
              onClick={onClick}
              customClassNames={`mt-40 !h-[56px] !font-medium !text-button-lg text-[#1b1b1b] ${
                isFullSizeButtonInMobile && 'w-full lg:w-auto'
              }`}>
              <div className="flex items-center space-x-6">
                <p className="text-neutral-10">{ctaText}</p>
                {showRedirectIcon && (
                  <span>
                    <IconByName name="icon-l-right" />
                  </span>
                )}
              </div>
            </RoundedButton>
          )}
        </div>
      </div>
    </div>
  );
};

InfoWithGraphic.defaultProps = {
  direction: DIRECTION.LTR,
  onCtaClick: () => {},
  isRedirectType: false,
  graphicBorder: BORDER_TYPE.motion_normal
};

export default InfoWithGraphic;
