import React, { useMemo } from 'react';
import TabsV2 from '@/components/common/TabsV2';
import CommunityStoryCard from '@/pages/home/components/CommunityStoryCard';
import RoundedButton from '@/components/common/RoundedButton';
import { useRouter } from 'next/router';

const CommunityCategories = ({
  title,
  activeTabId,
  communities,
  onTabClick,
  cta
}) => {
  const router = useRouter();

  const tabs = useMemo(() => {
    const categories = Object.keys(communities);
    return categories.map((cat, idx) => {
      return {
        label: cat,
        id: idx
      };
    });
  }, [communities]);

  const cards = useMemo(
    () => communities?.[tabs?.[activeTabId]?.label],
    [tabs, activeTabId, communities]
  );

  const renderCards = useMemo(
    () =>
      cards?.map((card, index) => (
        <CommunityStoryCard {...card} key={index} />
      )),
    [cards]
  );

  return (
    <div className="bg-gradient-to-b to-[#FFFAE8] from-[#ffffff]">
      <div className="py-64 mx-auto md:py-80">
        <h2 className="px-24 mb-40 font-semibold text-heading-xl text-npl-text-icon-on-light-surface-primary md:px-40 md:text-center md:text-display-sm md:mb-44">
          {title}
        </h2>
        <div className="px-24 mb-24 md:px-40 md:mb-36">
          <TabsV2
            tabs={tabs}
            activeTabId={activeTabId}
            onTabClick={onTabClick}
            customContainerClassNames="border-b-0 h-fit overflow-auto hide-scrollbar overscroll-x-contain md:overflow-visible md:items-center md:justify-center"
            labelClassNames="py-8 text-npl-text-icon-on-light-surface-secondary md:text-heading-sm"
            activeLabelClassNames="py-8 text-npl-text-icon-on-light-surface-primary md:text-heading-sm"
            strikeClassNames="relative rounded-8"
            customTabClassNames="!mr-8 md:!mr-12"
            showHoveStrike={true}
          />
        </div>
        <div className="flex px-24 space-x-8 overflow-scroll hide-scrollbar snap-x overscroll-x-contain md:px-40 md:sapce-x-12 lg:overflow-visible lg:justify-center lg:space-x-16">
          {renderCards}
        </div>
        {cta && (
          <div className="flex justify-center mx-16 mt-40">
            <RoundedButton
              displayType={cta?.type}
              onClick={() => {
                router.push(cta?.url);
              }}
              customClassNames="!h-[56px] !font-medium !text-button-lg w-full lg:w-auto"
              data-testid="homepage-community-categories-section-start-button">
              {cta?.text}
            </RoundedButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityCategories;
