import Icon from 'icons';
import { useRouter } from 'next/router';
import React from 'react';
import WhatsappBanner from '@/pages/home/components/WhatsappBanner/WhatsappBanner';

const CommunityTools = ({ title, pillList, isGtEqMd }) => {
  const router = useRouter();

  return (
    <div className="px-16 py-48 md:pt-24 md:pb-64 md:px-24">
      <h2
        className="text-[#1b1b1b] text-heading-xs font-semibold text-center mb-24 md:text-heading-lg"
        data-testid="homepage-community-tools-title">
        {title}
      </h2>
      <div className="flex flex-wrap justify-center mb-24 md:max-w-[1120px] md:mx-auto md:mt-auto">
        {pillList.map((pill, index) => {
          return (
            <div
              key={index}
              role="button"
              tabIndex={0}
              className="p-12 rounded-full space-x-[6px] flex items-center border-1 border-npl-neutral-light-6 mb-8 mr-8 md:mb-12 md:mr-12 md:active:bg-npl-neutral-light-2 md:hover:border-npl-neutral-light-12 md:hover:shadow-[0px_0px_0px_1px_#1B1B18]"
              onClick={() => {
                if (pill.url) router.push(pill.url);
              }}>
              <Icon
                name={pill.icon}
                fill={pill.color}
                width="20"
                height="20"
              />
              <span className="text-label-md font-medium text-[#1b1b1b] md:text-label-lg">
                {pill.text}
              </span>
            </div>
          );
        })}
      </div>
      <WhatsappBanner
        isGtEqMd={isGtEqMd}
        title="Try Nas.io for your WhatsApp group"
        description="Member management and Monetisation just became 10x easier"
        onClick={() => router.push('/whatsapp')}
      />
    </div>
  );
};

export default CommunityTools;
