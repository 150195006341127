import React from 'react';
import RoundedButton from '@/components/common/RoundedButton';
import WhatsappBannerIcon from '@/pages/home/components/WhatsappBanner/WhatsappBannerIcon';
import Icon from '@/icons/index';

const WhatsappBanner = ({
  title,
  description,
  ctaText,
  onClick,
  isGtEqMd
}) => {
  return (
    <div className="relative bg-npl-succes-light-3 flex flex-col justify-center text-center items-center rounded-28 py-80 px-16 md:px-0 md:py-40 md:max-w-[900px] md:m-auto">
      <div className="flex items-center px-8 py-4 mb-12 space-x-4 bg-npl-yellow-light-3 border-1 border-npl-yellow-light-6 rounded-4">
        <Icon name="star-filled-02" width={16} height={16} />
        <span className="font-medium text-overline-md text-npl-yellow-light-11">
          NEW
        </span>
      </div>
      <p className="mb-8 font-semibold text-npl-text-icon-on-light-surface-primary text-heading-md md:text-heading-lg">
        {title}
      </p>
      <p className="mb-32 text-body-md text-npl-text-icon-on-light-surface-primary">
        {description}
      </p>
      <RoundedButton
        displayType="static-primary"
        size="md"
        onClick={onClick}>
        {ctaText}
      </RoundedButton>
      <div className="absolute right-0 top-[16px] md:top-[50px]">
        <WhatsappBannerIcon
          width={isGtEqMd ? '160' : '80'}
          height={isGtEqMd ? '160' : '80'}
        />
      </div>
    </div>
  );
};

WhatsappBanner.defaultProps = {
  ctaText: 'Learn more'
};

export default WhatsappBanner;
